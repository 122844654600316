export const MainURL = {
  
    //LOCAL SERVER
    //  HostUrl: 'http://localhost:9094',

    //DEAL GROCERY ORIGNAL
    // HostUrl: 'http://192.168.0.23:8086',


    // http://192.168.0.14:1111

    //ZPLUS SERVER SERVER TESTING
    HostUrl: 'http://148.72.245.102:8086',


    // HostUrl: 'http://localhost:9094',

    // HostUrl: 'http://192.168.0.21:9094',
    imageUrl: '/assets'
  }
 

  